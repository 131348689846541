import * as React from 'react'
import { useState, useEffect } from 'react'
import Layout from '/src/components/layout'

import { stations } from '/src/styles/favoriter.module.css'
import { popupTitle, popupAddress, popupConnectors, popupNetwork, popupReadmore, popupFavorite } from '/src/styles/stationer-karta.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faStar, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { connectorTypeIDs } from '/src/components/support.js'

const FavoriterPage = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [favStations, setFavStations] = useState({})
    
    var tmpFav = []
    if(typeof window !== 'undefined') {
        tmpFav = JSON.parse(localStorage.getItem('favorites')) || []
    }
    const [favoritesList, setFavoritesList] = useState(tmpFav)

    const getData = () => {
        setIsLoading(true)
        
        fetch(`${process.env.GATSBY_API_URL}`+'/stations', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            var tmpList = []
            Object.keys(myJson).map((key) => {
                //if(Object.keys(favoritesList).includes(myJson[key].id.toString())) {
                if(favoritesList.includes(myJson[key].id)) {
                    tmpList.push(myJson[key])
                }
            })
            
            setFavStations(tmpList)
            setIsLoading(false)
        });
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(typeof window !== 'undefined') {
            localStorage.setItem('favorites', JSON.stringify(favoritesList))
        }
    }, [favoritesList])

    const handleFavoriteChange = event => {
        if(typeof window !== 'undefined') {
            const stationID = parseInt(event.currentTarget.getAttribute("data-stationid"))
            let confirmAction = window.confirm("Är du säker att du vill ta bort stationen från favoriter?")
            if(confirmAction) {
                setFavoritesList(favoritesList.filter(item => item !== stationID))
                setFavStations(favStations.filter(item => item.id !== parseInt(stationID)))
            }
        }
    };
    
    return (
        <Layout pageTitle="Favoriter">
            
            <p className='intro'>
                <i>Här visas de stationer som du markerat som favorit</i> <FontAwesomeIcon icon={faStar} style={{color: '#FFD700'}} />
            </p>

            { isLoading &&
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} />
                </div>
            }

            <div className={stations}>
                {
                    favStations && favStations.length > 0 && favStations.map((item) => {

                        var tmpAddressStr = item.street;
                        if(item.zipcode != null) {
                            tmpAddressStr += ', '+ item.zipcode.toString().substring(0, 3) +' '+ item.zipcode.toString().substring(3, 5);
                        }
                        if(item.city != null) {
                            tmpAddressStr += ' ' + item.city;
                        }
                        
                        return(
                            <div key={item.id}>
                                <FontAwesomeIcon icon={faStar} className={popupFavorite} style={{color: '#FFD700'}} data-stationid={item.id} onClick={handleFavoriteChange} />
                                <p className={popupNetwork}><img src={item.network.logoURL} alt={item.network.name} /></p>
                                <p className={popupTitle}>{item.name}</p>
                                <p className={popupAddress}>{tmpAddressStr}</p>

                                <table className={popupConnectors} style={{paddingBottom: '20px'}}>
                                    <tbody>
                                        {
                                            item.connectors && Object.keys(item.connectors).length > 0 && item.connectors.map((row) => {
                                                return (
                                                    <tr>
                                                        <td><b>{row['amount']} st</b></td>
                                                        <td>{row['kW']} kW</td>
                                                        <td><img src={"/connectors/"+row['type']+".svg"} alt="Ikon" /></td>
                                                        <td>{connectorTypeIDs[row['type']] ? connectorTypeIDs[row['type']] : row['type']}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <a href={'/stationer/'+item.id} target="_blank" rel="noreferrer">
                                    <p className={popupReadmore} style={{position: 'absolute', bottom: '10px', width: 'calc(100% + 2px)'}}>
                                        <b>Läs mer</b> &nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </p>
                                </a>
                            </div>
                        )
                    })
                }

            </div>

        </Layout>
    )
}

export default FavoriterPage