// extracted by mini-css-extract-plugin
export var mapPositionButton = "stationer-karta-module--mapPositionButton--x0MZC";
export var popupAddress = "stationer-karta-module--popupAddress--BY72r";
export var popupConnectors = "stationer-karta-module--popupConnectors--fbHTK";
export var popupFavorite = "stationer-karta-module--popupFavorite--yxxad";
export var popupNetwork = "stationer-karta-module--popupNetwork--bUpWv";
export var popupReadmore = "stationer-karta-module--popupReadmore--nXxPj";
export var popupTitle = "stationer-karta-module--popupTitle--uJ1kF";
export var spin = "stationer-karta-module--spin--OCZ6I";
export var stationsCount = "stationer-karta-module--stationsCount--CkQ+p";
export var stationsMap = "stationer-karta-module--stationsMap--NeTRw";